<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-filter :rules="rules" v-on:filter-change="updateFilter"></my-filter>
        </div>
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" :data-rows="admins" :actions="actions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { MessageBox, Message, Dialog, Table, TableColumn, Row, Col, Checkbox, CheckboxGroup, Button } from 'element-ui'
  import MyTable from 'src/components/UIComponents/Table.vue'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import adminSchemas from './admin-schemas'
  import dtHelper from 'src/helpers/datatable'
  import { mapState } from 'vuex'

  export default{
    components: {
      MyTable,
      ElDialog: Dialog,
      ElTable: Table,
      ElRow: Row,
      ElCol: Col,
      ElButton: Button,
      ElCheckbox: Checkbox,
      ElCheckboxGroup: CheckboxGroup,
      ElTableColumn: TableColumn,
      MyFilter
    },
    computed: {
      admins() {
        let rows = this.$store.state.admins;
        return dtHelper.filterByRules(rows, this.filterOutput);
      },
    },
    data () {
      return {
        filterOutput: [],
        columnDefs: dtHelper.buildColumDefs(adminSchemas),
        actions: [
          {
            type: 'primary',
            icon: 'nc-icon nc-ruler-pencil',
            callback: this.edit
          },
          {
            type: 'danger',
            icon: 'nc-icon nc-simple-remove',
            callback: this.remove
          }
        ],
        filter: {},
        rules: dtHelper.buildRules(adminSchemas)
      }
    },
    mounted() {
      this.$store.dispatch('fetchStaffs');
      this.$store.dispatch('setPageTitle', 'Quản lý admin');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Tạo mới',
        type: 'primary',
        icon: '',
        callback: this.createAdmin
      }])
    },
    methods: {
      updateFilter(filterOutput) {
        this.filterOutput = filterOutput;
      },
      edit(index, row){
        this.$router.push('/admins/' + row.id);
      },
      createAdmin() {
        this.$router.push('/admins/create');
      },

      remove(index, row) {
        MessageBox.confirm('Xóa admin?', 'Warning', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
          type: 'warning',
          center: true
        }).then(() => {
          this.$store.dispatch('removeAdmin', row.id).then(res => {
            if (res.success) {
              Message({ message: 'Đã xóa thành công', type: 'success' });
              this.$store.dispatch('fetchStaffs');
            } else {
              Message({ message: res.message, type: 'error' });
            }
          });
        });
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
<style lang="scss">
  .el-upload-custom{
    width: 128px;
  }
</style>
