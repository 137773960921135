export default [
  {
    prop: 'id',
    label: 'ID',
    minWidth: 80,
    type: 'id'
  },
  {
    prop: 'name',
    label: 'Họ tên',
    minWidth: 120,
    type: 'text',
  },
  {
    prop: 'email',
    label: 'Email',
    minWidth: 150,
    type: 'text'
  },
  {
    prop: 'phone',
    label: 'Số điện thoại',
    minWidth: 120,
    type: 'text'
  },
  {
    prop: 'role_name',
    label: 'Phân quyền',
    minWidth: 120,
    type: 'text'
  },

]
